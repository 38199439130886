import React from 'react';
import cn from 'classnames';
import Images from '@components/Images';
import Layout from '@components/Layouts/Layout';
import { Header } from '@components/Blocks/Header';
import { SchemaOrgOrganization } from '@components/Layouts/SchemaOrg/SchemaOrgOrganization';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';

import styles from './about.module.scss';

export function Head() {
  return (
    <>
      <SEO title="О нас" description="Периодика" />
      <YandexTableau />
      <SchemaOrgOrganization />
    </>
  );
}

function About() {
  return (
    <Layout>
      <div className={styles.wrapper}>
        <Images src="new-about.jpg" className={styles.image} alt="About" />
        <div className={styles.content}>
          <div className={styles.title}>
            <Header>О Периодике</Header>
            <p>
              Мы создаем удобный сервис печати фотокниг и помогаем сохранять воспоминания о самых
              важных моментах жизни.
            </p>
            <br />
            <p>
              Собрать и заказать фотокнигу можно в удобном конструкторе на сайте или в
              iOS-приложении, которое доступно даже без подключения к интернету.
            </p>
          </div>
          <div
            className={cn({
              [styles.block]: true,
              [styles.jobs]: true,
            })}
          >
            <h3>Вакансии</h3>
            <p>Ищем коллег в нашу амбициозную команду!</p>
            <a
              href="https://landing.periodica.press/careers"
              target="_blank"
              rel="noreferrer"
              className={styles.btn}
            >
              Подробнее
            </a>
          </div>
        </div>

        <div className={styles.blocksContainer}>
          <div
            className={cn({
              [styles.block]: true,
              [styles.tabletJobs]: true,
            })}
          >
            <h3>Вакансии</h3>
            <p>Ищем коллег в нашу амбициозную команду!</p>
            <a
              href="https://landing.periodica.press/careers"
              target="_blank"
              rel="noreferrer"
              className={styles.btn}
            >
              Подробнее
            </a>
          </div>
          <div className={styles.blockCard}>
            <h3>Миссия Периодики</h3>
            <p>
              Рассказываем, почему для нас печать фотографий — важная традиция, и в чем сила
              напечатанных историй
            </p>
            <a
              className={styles.btn}
              href="https://landing.periodica.press/values"
              target="_blank"
              rel="noreferrer"
            >
              Подробнее
            </a>
          </div>
          <div className={styles.blockCard}>
            <h3>Как собрать фотокнигу</h3>
            <p>Делимся советами, которые помогут сделать первый шаг к своей напечатанной истории</p>
            <a href="/blog/howto" className={styles.btn} target="_blank">
              Руководство
            </a>
          </div>
          <div className={styles.blockCard}>
            <h3>Вдохновение</h3>
            <p>
              В блоге мы делимся советами по сборке фотокниг для разных поводов, созданию красивых
              фотографий и сохранению воспоминаний
            </p>
            <a href="/help" className={styles.btn} target="_blank">
              Советы
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
